<template>
  <div class="card card-custom">
    <template v-if="!showResult">
      <div class="card-header">
        <h4 class="card-title">{{ getChecklistInfo.processName }}</h4>
        <p class="card-subtitle">{{ getChecklistInfo.description }}</p>
      </div>
      <template v-if="getChecklistInfo.visibleType">
        <div class="card-body">
          <template
            v-if="
              getChecklistInfo.relatesProcess_id !== null &&
                !checkEmpty(getChecklistInfo.mainProc)
            "
          >
            <h6 class="card-title">
              Тестирование с заключением специалиста
              {{ getChecklistInfo.mainProc.process.processName }} заполнил
              {{
                `${getChecklistInfo.mainProc.User.first_name} ${getChecklistInfo.mainProc.User.last_name} (${getChecklistInfo.mainProc.User.username})`
              }}
            </h6>
            <b-list-group>
              <b-list-group-item
                v-for="answer in getChecklistInfo.mainProc.answers"
                :key="answer.id"
                class="d-flex justify-content-between align-items-center"
              >
                <div>
                  <b-badge variant="success" pill>{{
                    answer.task.type
                  }}</b-badge>
                  {{ answer.task.name }}:
                </div>
                <b-badge
                  variant="primary"
                  pill
                  v-if="answer.task.type === 'Дата'"
                  >{{ parseTime(answer.answer, "dd.mm.yyyy") }}</b-badge
                >
                <template v-else-if="answer.task.type === 'Множественный выбор'"
                  ><b-badge
                    variant="primary"
                    pill
                    v-for="qa in answer.answers"
                    :key="qa"
                    >{{ qa }}</b-badge
                  ></template
                >
                <img
                  v-else-if="
                    answer.task.type === 'Графический ответ' ||
                      answer.task.type === 'Фото'
                  "
                  :src="drawImage(answer.answer)"
                  style="max-width:50%;"
                />
                <template v-else-if="answer.task.type === 'Сотрудник'">
                  <b-badge
                    variant="primary"
                    pill
                    v-for="qa in answer.answers"
                    :key="qa.id"
                    >{{ qa.first_name }} {{ qa.last_name }} ({{
                      qa.username
                    }})</b-badge
                  >
                </template>
                <b-badge variant="primary" pill v-else>{{
                  answer.answer
                }}</b-badge>
              </b-list-group-item>
            </b-list-group>
            <h6 class="card-title">
              Оформите ваше заключение
            </h6>
          </template>

          <Inspection
            v-if="!checkEmpty(checklist)"
            :process="getChecklistInfo"
            :inspection="checklist"
          ></Inspection>

          <div
            class="card bg-light-primary mt-3"
            v-if="!checkEmpty(getChecklistInfo.mainProc)"
          >
            <div class="card-body">
              <div class="row">
                <label class="col-12"
                  >Укажите новый статус исполнения
                  <span :class="{ 'text-danger': status === '' }"
                    >*</span
                  ></label
                >
              </div>
              <div class="row">
                <div class="col-12">
                  <b-form-radio-group v-model="status" buttons>
                    <b-form-radio value="Прием" button-variant="outline-danger">
                      Рекомендован прием специалиста
                    </b-form-radio>
                    <b-form-radio
                      value="Консультация"
                      button-variant="outline-warning"
                    >
                      Рекомендована консультация специалиста
                    </b-form-radio>
                    <b-form-radio
                      value="Отлично"
                      button-variant="outline-success"
                    >
                      У вас не выявлено отклонений
                    </b-form-radio>
                  </b-form-radio-group>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="d-flex justify-content-between border-top pt-10">
            <div>
              <button
                v-on:click="submit"
                class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                data-wizard-type="action-submit"
              >
                Отправить
              </button>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="card-body">
          <Byone
            v-if="!checkEmpty(checklist)"
            :process="getChecklistInfo"
            :inspection="checklist"
          ></Byone>
        </div>
      </template>
    </template>
    <template v-else>
      <template v-if="payment">
        <div class="card-header">
          <h4 class="card-title text-success">Тест упешно пройден!</h4>
        </div>
        <div class="card-body">
          <div class="d-flex justify-content-center mb-3">
            <template v-if="showSpinner">
              <p>
                Для получения результата, пожалуйста проведите оплату в размере
                <b>{{ price }} руб.</b>
              </p>
              <br />
              <b-spinner label="Загрузка..."></b-spinner>
            </template>
            <div id="payment-form"></div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="card-header">
          <h4 class="card-title text-success">Тест упешно пройден!</h4>
        </div>
        <div class="card-body">
          <div class="d-flex justify-content-center mb-3">
            <p>
              Результат будет направлен на указанную вами почту, можете закрыть
              данную страницу.
            </p>
            <br />
          </div>
        </div>
      </template>
    </template>
    <div class="card-footer">
      <a
        href="https://chekr.ru/"
        target="_blank"
        class="text-dark-75 text-hover-primary"
      >
        Подготовлено с помощью сервиса Che(k)r.ru
      </a>
    </div>
    <!-- PAYMENT MODAL -->
    <!--<b-modal
      ref="my-modal"
      hide-footer
      hide-header-close
      title="Завершение теста"
    >
      <div class="d-block text-center">
        <h3 class="text-success">Тест упешно пройден!</h3>
        <p>
          Для получения результата, пожалуйста проведите оплату в размере
          <b>1 рубля</b>
        </p>
      </div> -->
    <!--<b-button class="mt-3" variant="outline-danger" block @click="hideModal"
        >Закрыть</b-button
      >
      <b-button
        class="mt-2"
        variant="outline-warning"
        block
        @click="toggleModal"
        >Toggle Me</b-button
      >-->
    <!-- </b-modal> -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  FETCH_CHECKLIST_BY_UUID,
  SET_CHECKLIST,
  SAVE_CHECKLIST
} from "@/core/services/store/checklist.module";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import KTUtil from "@/assets/js/components/util";

import Inspection from "@/view/pages/chekkr-checklist/components/Inspection";
import Byone from "@/view/pages/chekkr-checklist/components/Byone";

export default {
  name: "qr",
  components: {
    Inspection,
    Byone
  },
  data() {
    var d = new Date();
    var month = "" + (d.getMonth() + 1);
    var day = "" + d.getDate();
    var year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    var today = [year, month, day].join("-");
    return {
      mailgroups: [],
      selectedMailgroups: [],
      today: today,
      status: "",
      showResult: false,
      showSpinner: true,
      payment: false,
      price: 0
    };
  },

  computed: {
    ...mapGetters([
      "layoutConfig",
      "getChecklistInfo",
      "getCommonInfo",
      "isChecklistLoaded",
      "getUuid",
      "getFinishedId"
    ]),

    config() {
      return this.layoutConfig();
    },

    checklist: {
      get: function() {
        return this.$store.getters.getCheckList;
      },
      set: function(checklist) {
        this.$store.commit(SET_CHECKLIST, checklist);
      }
    },

    selectAllMailgroups: {
      get: function() {
        return this.mailgroups
          ? this.selectedMailgroups.length == this.mailgroups.length
          : false;
      },
      set: function(value) {
        var selectedMailgroups = [];
        if (value) {
          this.mailgroups.forEach(function(role) {
            selectedMailgroups.push(role.id);
          });
        }

        this.selectedMailgroups = selectedMailgroups;
      }
    }
  },

  created() {},

  mounted() {
    var q = this.$route.query.id;
    // console.log(q);
    if (typeof q !== "undefined") {
      this.$store
        .dispatch(FETCH_CHECKLIST_BY_UUID, {
          uuid4: q
        })
        .then(() => {
          //this.$router.push("/checklist/operate").catch(err => {
          //throw new Error(`Problem handling something: ${err}.`);
          //});
        })
        .catch(response => {
          console.error(response);
        });
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Заполнение" },
      { title: "Тесты", route: "/checklist/all" }
    ]);
    let yookassascript = document.createElement("script");
    yookassascript.setAttribute(
      "src",
      "https://yookassa.ru/checkout-widget/v1/checkout-widget.js"
    );
    document.head.appendChild(yookassascript);
  },

  methods: {
    drawImage(src) {
      if (!src.includes(this.$host)) {
        src = this.$host + src;
      }
      return src;
    },

    async loadMainProc() {
      // console.log(this.getChecklistInfo.relatesProcess_id);
      try {
        return new Promise(resolve => {
          ApiService.post("api/v1/ra2/", {
            status_id: this.getChecklistInfo.relatesProcess_id
          }).then(({ data }) => {
            //     console.log(data);
            this.getChecklistInfo.mainProc = data;
            this.timeSpent =
              new Date(data.date_end) - new Date(data.date_begin);
            data.answers.sort((a, b) => a.id - b.id);
            resolve(data);
          });
        });
      } catch ({ response }) {
        console.error(response);
      }
    },

    async loadMailgroups() {
      try {
        return new Promise(resolve => {
          ApiService.post("api/v1/findCustomData/", {
            user_id: this.$store.getters.currentUser.user.id,
            type: "mailer"
          }).then(({ data }) => {
            this.mailgroups = data;
            resolve(data);
          });
        });
      } catch ({ response }) {
        console.error(response);
      }
    },

    validateQuestions(arr) {
      var retarr = [];
      arr.forEach(quest => {
        if (quest.answers.length > 0) {
          if (
            quest.answer !== null &&
            typeof quest.answer !== "undefined" &&
            quest.answer !== [] &&
            quest.answer !== ""
          ) {
            quest.answers.forEach(a => {
              if (
                a.hasOwnProperty("questions") && Array.isArray(quest.answer)
                  ? quest.answer.includes(a.id)
                  : a.id === quest.answer
              ) {
                // console.log("we are here");
                //  console.log(a.questions);
                if (typeof a.questions !== "undefined") {
                  retarr = retarr.concat(
                    a.questions,
                    this.validateQuestions(a.questions)
                  );
                }
              }
            });
          }
        }
      });
      return retarr;
    },

    validateChecklist(obj) {
      var questions = [];
      // eslint-disable-next-line no-useless-escape
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (obj.hasOwnProperty("section_finish")) {
        questions = obj.section_start.questions.concat(
          obj.section_finish.questions
        );
      } else {
        questions = obj.section_start.questions;
      }
      obj.sections.forEach(section => {
        questions = questions.concat(section.questions);
        questions = questions.concat(this.validateQuestions(section.questions));
      });
      if (
        questions.findIndex(
          q =>
            (q.dataTypeId_id === 3 &&
              q.questName.includes("email") &&
              !re.test(q.answer)) ||
            (q.requared &&
              (q.answer === null ||
                typeof q.answer === "undefined" ||
                q.answer === [] ||
                q.answer === "" ||
                q.answer.length < 1))
        ) === -1
      ) {
        return true;
      } else {
        return false;
      }
    },

    submit: function(e) {
      e.preventDefault();
      //validate before saving to server
      // console.log(this.validateChecklist(this.checklist));
      if (this.validateChecklist(this.checklist)) {
        this.payment = this.getChecklistInfo.payment;
        this.price = this.getChecklistInfo.price;
        this.$store
          .dispatch(SAVE_CHECKLIST, {
            mailgroups: [],
            targetId: 2
            // status: this.status
          })
          .then(() => {
            /* Swal.fire({
              title: "",
              text: "Тест успешно пройден!",
              icon: "success",
              confirmButtonClass: "btn btn-secondary"
            }); */
            // window.location.replace("https://chekr.ru/");
            //this.showModal();
            this.showResult = true;
            //    console.log(this.getFinishedId);
            if (this.getFinishedId) {
              ApiService.post(`/api/v1/createPayment/`, {
                statusId: this.getFinishedId,
                price: this.price
              })
                .then(({ data }) => {
                  //      console.log(data);
                  //Инициализация виджета. Все параметры обязательные.
                  const checkout = new window.YooMoneyCheckoutWidget({
                    confirmation_token: data.confirmation_token, //Токен, который перед проведением оплаты нужно получить от ЮKassa
                    return_url: `${this.$host}/#/paymentsuccess?id=${this.getFinishedId}`, //Ссылка на страницу завершения оплаты, это может быть любая ваша страница
                    //При необходимости можно изменить цвета виджета, подробные настройки см. в документации
                    customization: {
                      //Настройка цветовой схемы, минимум один параметр, значения цветов в HEX
                      colors: {
                        //Цвет акцентных элементов: кнопка Заплатить, выбранные переключатели, опции и текстовые поля
                        control_primary: "#1BC5BD", //Значение цвета в HEX

                        //Цвет платежной формы и ее элементов
                        background: "#E1F0FF" //Значение цвета в HEX
                      }
                    },
                    error_callback: function(error) {
                      console.error(error);
                    }
                  });
                  //Отображение платежной формы в контейнере
                  checkout.render("payment-form");
                  this.showSpinner = false;
                })
                .catch(err => {
                  console.error(`Ошибка обращения за созданием платежа ${err}`);
                });
            }
          })
          .catch(error => {
            console.error(error);
            Swal.fire({
              title: "",
              text: "Произошла ошибка, повторите попытку!",
              icon: "error",
              confirmButtonClass: "btn btn-secondary"
            });
          });
      } else {
        Swal.fire({
          title: "",
          text: "Заполните обязательные поля, отмеченные *!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary"
        });
      }
    },

    findObjectByKeyVal: function(obj, key, val) {
      if (!obj || typeof obj === "string") {
        return null;
      }
      if (obj[key] === val) {
        return obj;
      }

      for (var i in obj) {
        if (obj.hasOwnProperty(i)) {
          var found = this.findObjectByKeyVal(obj[i], key, val);
          if (found) {
            return found;
          }
        }
      }
      return null;
    },

    checkEmpty(obj) {
      return KTUtil.isEmpty(obj);
    },

    parseTime(datetime, format = "dd.mm") {
      var newdate = new Date(datetime);
      var dd = String(newdate.getDate()).padStart(2, "0");
      var mm = String(newdate.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = newdate.getFullYear();
      //var yy = yyyy.toString().substr(-2);
      //return dd + '.' + mm + '.' + yyyy;
      if (format === "dd.mm") {
        return dd + "." + mm;
      }
      if (format === "dd-mm-yyyy") {
        return dd + "-" + mm + "-" + yyyy;
      }
    }

    /* showModal() {
      this.$refs["my-modal"].show();
    },

    hideModal() {
      this.$refs["my-modal"].hide();
    },

    toggleModal() {
      this.$refs["my-modal"].toggle("#toggle-btn");
    } */
  }
};
</script>
